<template>
  <b-container fluid class="mt-5">
    <b-row>
      <b-col class="text-center">
        <b-img src="@/assets/dark1.svg"></b-img>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col xs="12" sm="8" md="6" lg="5" class="max-obd-width">
        <b-container fluid>
          <b-card class="mt-3">
            <b-col class="text-center"> </b-col>
            <b-card-body id="ValidateToken" v-if="!submited" class="pt-3">
              <b-row>
                <b-col class="text-center">
                  <strong> Solicite su invitación </strong>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center pt-3 pb-3 icon-token">
                  <!-- <img src="@/assets/id-badge-solid.svg" width="92px" /> -->
                  <welcome :color="getMainColor" />
                </b-col>
              </b-row>
              <b-row class="pb-5">
                <b-col class="text-center">
                  Por favor, completar los campos a continuación
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="signup">
                    <b-form>

                      <b-form-group
                        id="input-group-2"
                        :description="nombreDesc"
                        label-for="signup-2"
                      >
                        <b-form-input
                          id="signup-2"
                          placeholder="Nombre"
                          type="text"
                          autocomplete="off"
                          v-model="$v.form.data.nombre.$model"
                          :state="nombreState"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="input-group-3"
                        :description="apellidoDesc"
                        label-for="signup-3"
                      >
                        <b-form-input
                          id="signup-3"
                          placeholder="Apellido"
                          autocomplete="off"
                          type="text"
                          v-model="$v.form.data.apellido.$model"
                          :state="apellidoState"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="input-group-1"
                        label-for="signup-1"
                        :description="emailDesc"
                      >
                        <b-form-input
                          :state="emailState"
                          id="signup-1"
                          type="email"
                          v-model.lazy="$v.form.data.email.$model"
                          placeholder="Email"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-button
                        class="align-items-center"
                        block
                        v-b-tooltip.hover
                        title="SOLICITAR INVITACIÓN"
                        @click.prevent="validateToken"
                        size="lg"
                        variant="primary"
                        :disabled="
                          !$v.$anyDirty ||
                          $v.$anyError ||
                          this.loading ||
                          this.form.data.email == ''
                        "
                      >
                        <span v-if="!this.loading" class="mb-2 form-text">SOLICITAR INVITACIÓN</span>
                        <b-spinner type="grow" v-else label="Loading..."></b-spinner>
                      </b-button>
                    </b-form>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-body id="ActivateOk" v-else class="pt-3">
              <b-row>
                <b-col class="text-center text-secondary" variant="secondary">
                  <!-- <svg style="width: 92px; height: 92px" viewBox="0 0 24 24">
                    <path
                      fill="#6E63E7"
                      d="M23.5 17L18.5 22L15 18.5L16.5 17L18.5 19L22 15.5L23.5 17M13 18H3V8L11 13L19 8V13H21V6C21 4.9 20.1 4 19 4H3C1.9 4 1 4.9 1 6V18C1 19.1 1.9 20 3 20H13V18M19 6L11 11L3 6H19Z"
                    />
                  </svg> -->
                  <mail-checked-icon :color="getMainColor" />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center">
                  <p>
                    Le enviaremos el código de invitación a su casilla de correo
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4" offset="4">
                  <b-button block :to="{ name: routeByOrigin }">
                    <span class="form-text">OK</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col>
        <span class="text-muted copyright"
          >Bithan ©2021 Todos los derechos reservados</span
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { required, email } from "vuelidate/lib/validators"
import userService from '@/services/userService'
import { mapGetters } from 'vuex'

export default {
  name: 'ValidateToken',
  components: {
    MailCheckedIcon: () => import('@/components/icons/mail-checked'),
    Welcome: () => import('@/components/icons/welcome')
  },
  data: () => {
    return {
      submited: false,
      loading: false,
      form: {
        data: { email: "", nombre: "", apellido: "" },
      },
    }
  },
   methods: {
    errorNotify(msg) {
      const notification = {
        message: msg,
        variant: "danger",
        countdown: 5,
      }
      this.$store.dispatch("notifications/notify", {
        notification: notification,
      })
    },
     validateToken() {
      this.loading = true
      userService.ClientTokenRequest(this.form.data.email, this.form.data.nombre, this.form.data.apellido)
        .then(resp => {
          if(resp.data.error !== 0) {
            this.errorNotify(resp.data.message)
          } else {
            this.submited = true
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

   computed: {
     ...mapGetters('user', [
      'routeByOrigin',
      'getMainColor'
    ]),
    emailDesc: function () {
      return !this.emailState ? "Ingrese un email válido" : ""
    },
    emailState: function () {
      return !this.$v.form.data.email.$invalid && this.$v.form.data.email.$dirty
    },
    nombreDesc: function () {
      return !this.nombreState ? "Ingrese un nombre válido" : ""
    },
    nombreState: function () {
      return !this.$v.form.data.nombre.$invalid && this.$v.form.data.nombre.$dirty
    },
    apellidoDesc: function () {
      return !this.apellidoState ? "Ingrese un apellido válido" : ""
    },
    apellidoState: function () {
      return !this.$v.form.data.apellido.$invalid && this.$v.form.data.apellido.$dirty
    }
  },

  validations: {
    form: {
      data: {
        email: {
          required,
          email,
        },
        nombre: {
          required
        },
        apellido: {
          required
        }
      },
    },
  },
}
</script>

<style>

</style>